import { Button } from '@chakra-ui/react'
import { IcoHome } from '@paper/icons'
import { ActiveRoute, createRouter, useRouter } from '@paper/route'
import { Error404Page } from './blocks/errors'
import { getEAProps } from './blocks/errors/errorUtils'
import { Routes } from './routes'

const Router = createRouter(Routes)
export function Root() {
  return (
    <Router notFound={(error) => <Root404 />}>
      <ActiveRoute />
    </Router>
  )
}

function Root404() {
  const { dispatchRoute } = useRouter()
  return (
    <Error404Page
      actions={
        <Button
          {...getEAProps(<IcoHome />)}
          onClick={() => dispatchRoute(Routes.home.mergeAction())}
        >
          Home
        </Button>
      }
    />
  )
}
